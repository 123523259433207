<template>
  <div class="main">
    <div id="status-bar"></div>
    <base-template
      :response="response"
      v-if="template === 'base' && isLoaded"
    ></base-template>
    <base-2022-template
      :response="response"
      v-if="template === 'base_2022' && isLoaded"
    ></base-2022-template>
    <alpine-template
      :response="response"
      v-if="template === 'alpine' && isLoaded"
    ></alpine-template>
    <template-estilo-peugeot
      :response="response"
      v-if="template === 'template_estilo_peugeot' && isLoaded"
    ></template-estilo-peugeot>
    <VueScriptComponent
      v-if="
        isLoaded === true &&
        (response.setup.seller_id == 159 || response.setup.seller_id == 442)
      "
      script='<script type="text/javascript">
      if(window._mtmReady){ 
      console.error("Connected sites script already loaded. You might have it dupplicated."); 
      } else { 
      window._mtmReady=true; 
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});
      var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
      g.async=true; g.src="https://egoi.site/1464356_lubrigaz.pt.js"; 
      s.parentNode.insertBefore(g,s);
      } </script>'
    />
    <VueScriptComponent
      v-if="isLoaded === true && response.setup.seller_id == 182"
      script='<script type="text/javascript">
      if(window._mtmReady){ 
      console.error("Connected sites script already loaded. You might have it dupplicated."); 
      } else { 
      window._mtmReady=true; 
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({"mtm.startTime": (new Date().getTime()), "event": "mtm.Start"});
      var d=document, g=d.createElement("script"), s=d.getElementsByTagName("script")[0];
      g.async=true; g.src="https://egoi.site/1464356_lubrisport.pt.js"; 
      s.parentNode.insertBefore(g,s);
      } </script>'
    />

    <VueScriptComponent
      v-if="isLoaded === true && response.setup.seller_id == 435"
      script='<script>
		  !function (w, d, t) {
		  w.TiktokAnalyticsObject=t;
      var ttq=w[t]=w[t]||[];
      ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
      ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
      for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
      ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;
      n<ttq.methods.length;
      n++)ttq.setAndDefer(e,ttq.methods[n]);
      return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
      ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
      n=document.createElement("script");
      n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;
      e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};		
		  ttq.load("CKTNT8BC77U5FRI5TB1G");
		  ttq.page();
		  }(window, document, "ttq");
	    </script>'
    />
   
  </div>
</template>

<script>
import {v4 as uuidv4}     from 'uuid';
import VueScriptComponent from 'vue-script-component';

export default {
	name       : 'Index',
	components : {
		BaseTemplate          : () => import('@/templates/Base'),
		Base2022Template      : () => import('@/templates/Base2022'),
		AlpineTemplate        : () => import('@/templates/Alpine'),
		CatalogV1             : () => import('@/templates/CatalogV1'),
		CatalogV2             : () => import('@/templates/CatalogV2'),
		TemplateEstiloPeugeot : () => import('@/templates/TemplateEstiloPeugeot'),
		VueScriptComponent,
	},
	data() {
		return {
			response : Object,
			isLoaded : false,
			template : '',
		};
	},
	async mounted() {
		this.response = this.$store.state.resData.data;
		this.template = this.response.template;
		this.isLoaded = true;

		let alid = uuidv4();
		await this.$store.dispatch('getAlId', alid);

		window.alp.init(this.response.setup.fb_pixel_id, alid);
		window.alp.trackEvent({ event : 'PageView' });

		let fullUrl = window.location.href;
		if (fullUrl.match(/(:\/\/viaturas|viaturas-|-viaturas-)/) !== null) {
			this.checkIsStockListing(fullUrl, 1);
		} else {
			this.checkIsStockListing(fullUrl, 0);
		}
	},
	methods : {
		checkIsStockListing(fullUrl, length) {
			if (
				(fullUrl.match(/viaturas/g) || []).length > length &&
				!this.$route.params.hasOwnProperty('listing_id')
			) {
				if (process.env.NODE_ENV !== 'development') {
					window.location = this.response.redirect_url;
					return;
				}
				console.log('failed. redirecting -> ' + this.response.redirect_url);
			}
		},
	},
};
</script>
